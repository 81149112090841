
import {defineComponent, onMounted} from "vue";
import {themeName} from "@/core/helpers/documentation";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "changelog",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Resources", "Changelog");
    });

    return {
      themeName,
    };
  },
});
